// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AutoCompleteText {
  position: relative;
  z-index: 100;
}

.AutoCompleteText .input input {
  border: none;
  border-radius: 0;
}

.AutoCompleteText .input button {
  border: none;
  border-radius: 0;
}

.AutoCompleteText ul {
  width: 100%;
  position: absolute;
  list-style-type: none;
  text-align: left;
  color: black;
  background-color: white;
  margin: 0;
  padding: 0;
  border: 1px solid rgb(224, 225, 226);
  border-radius: 0 0 0.3rem 0.3rem;
}

.AutoCompleteText ul::before {
  content: "";
}

.AutoCompleteText li {
  padding: 0.3em 1em;
  cursor: pointer;
}

.AutoCompleteText li:hover {
  text-decoration: underline;
  background-color: rgb(224, 225, 226);
  border-radius: 0.3rem;
}`, "",{"version":3,"sources":["webpack://./src/lib/components/AutocompleteSearchBar/AutocompleteSearchBar.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,YAAA;AACF;;AAEA;EACE,YAAA;EACA,gBAAA;AACF;;AAEA;EACE,YAAA;EACA,gBAAA;AACF;;AAEA;EACE,WAAA;EACA,kBAAA;EACA,qBAAA;EACA,gBAAA;EACA,YAAA;EACA,uBAAA;EACA,SAAA;EACA,UAAA;EACA,oCAAA;EACA,gCAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,kBAAA;EACA,eAAA;AACF;;AAEA;EACE,0BAAA;EACA,oCAAA;EACA,qBAAA;AACF","sourcesContent":[".AutoCompleteText {\n  position: relative;\n  z-index: 100;\n}\n\n.AutoCompleteText .input input {\n  border: none;\n  border-radius: 0;\n}\n\n.AutoCompleteText .input button {\n  border: none;\n  border-radius: 0;\n}\n\n.AutoCompleteText ul {\n  width: 100%;\n  position: absolute;\n  list-style-type: none;\n  text-align: left;\n  color: black;\n  background-color: white;\n  margin: 0;\n  padding: 0;\n  border: 1px solid rgb(224, 225, 226);\n  border-radius: 0 0 0.3rem 0.3rem;\n}\n\n.AutoCompleteText ul::before {\n  content: '';\n}\n\n.AutoCompleteText li {\n  padding: 0.3em 1em;\n  cursor: pointer;\n}\n\n.AutoCompleteText li:hover {\n  text-decoration: underline;\n  background-color: rgb(224, 225, 226);\n  border-radius: 0.3rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
